import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios  from 'axios'

const app = createApp(App);

// axios.defaults.baseURL = 'https://livingcolor.web.id/api/v1'
// app.config.globalProperties.uri = 'https://livingcolor.web.id/api/v1'

axios.defaults.baseURL = 'https://tamaniservices.my.id/api/v1'
app.config.globalProperties.uri = 'https://tamaniservices.my.id/api/v1'

app.use(store).use(router).mount('#app')
