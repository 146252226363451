<template>
  <div class="b-container">
    <div class="login-container">
      <div class="heading" style="height: 100px">
        <img
          src="/logo.png"
          alt="logo.png"
          style="
            position: relative;
            width: 70%;
            height: 100px;
            object-fit: cover;
            margin-top: 0px;
          "
        />
      </div>

      <div class="form">
        <input
          required=""
          class="input"
          type="text"
          name="nik"
          id="nik"
          placeholder="NIK"
          v-model="nik"
          @keypress.enter="clickLogin"
        />
        <span class="error-message">{{ errorNik }}</span>

        <div style="position: relative; display: flex; align-items: center">
          <input
            required=""
            class="input"
            type="password"
            name="password"
            id="password"
            placeholder="Password"
            v-model="password"
            @keypress.enter="clickLogin"
          />
          <div class="show-pass" @click="showPass" id="show-password">
            <i v-if="showPassword" class="ri-eye-fill"></i>
            <i v-if="!showPassword" class="ri-eye-off-fill"></i>
          </div>
        </div>
        <span class="error-message">{{ errorPass }}</span>

        <!-- <span class="forgot-password"><a href="#">Forgot Password ?</a></span> -->
        <button class="login-button" id="login-button" type="submit" @click="login">
          <div v-if="is_proccess" class="spinner center">
            <div class="spinner-blade"></div>
            <div class="spinner-blade"></div>
            <div class="spinner-blade"></div>
            <div class="spinner-blade"></div>
            <div class="spinner-blade"></div>
            <div class="spinner-blade"></div>
            <div class="spinner-blade"></div>
            <div class="spinner-blade"></div>
            <div class="spinner-blade"></div>
            <div class="spinner-blade"></div>
            <div class="spinner-blade"></div>
            <div class="spinner-blade"></div>
          </div>

          <span v-if="!is_proccess">Sign In</span>
        </button>
      </div>

      <div class="social-account-container">
        <div class="social-accounts"></div>
      </div>
    </div>
  </div>

  <notification
    v-if="showAlert"
    :message="message"
    :success="success"
  ></notification>
</template>

<script>
import Notification from "@/components/Notification.vue";

export default {
  name: "LoginView",
  components: {
    Notification,
  },
  data() {
    return {
      nik: null,
      password: null,
      errorNik: null,
      errorPass: null,
      is_proccess: false,
      showPassword: false,
      message: null,
      success: false,
      showAlert: false,
    };
  },
  methods: {
    showPass() {
      const eyes = document.getElementById("show-password");
      if (this.showPassword === false) {
        this.showPassword = true;
        document.getElementById("password").type = "text";
        eyes.style.color = "var(--logincolor)";
      } else {
        this.showPassword = false;
        document.getElementById("password").type = "password";
        eyes.style.color = "var(--gray)";
      }
    },
    clickLogin(){
      document.getElementById("login-button").click()
    },
    async login() {
      if (!this.nik) this.errorNik = "Field NIK harus diisi!";
      if (!this.password) this.errorPass = "Field password harus diisi!";
      if (!this.nik || !this.password) return;

      this.is_proccess = true;

      const data = {
        nik: this.nik,
        password: this.password,
      };

      if (this.nik && this.password) {
        this.$store
          .dispatch("LOGIN", data)
          .then(() => {
            this.is_proccess = false;
            this.$router.push({ name: "dashboard" });
          })
          .catch((error) => {
            
            if (error.response.status == 406) {
              this.token = error.response.data.data;
              this.$router.push({
                name: "reset",
                params: { token: this.token },
              });
              return;
            }

            this.is_proccess = false;
            this.message = error.response.data.message;
            this.status = false;
            this.showAlert = true;

            setTimeout(() => {
                this.message = null;
                this.status = false;
                this.showAlert = false;
            }, 1300);
          });
      }

      setTimeout(() => {
        this.errorNik = null;
        this.errorPass = null;
      }, 5000);
    },
  },
};
</script>

<style>
.b-container {
  position: absolute;
  top: 0;
  left: 0;
  background: #dfdfdf;
  width: 100svw;
  height: 100svh;
  overflow-x: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}
.error-message {
  position: relative;
  top: 5px;
  margin-left: 20px;
  color: #740820;
  font-family: Arial, Helvetica, sans-serif;
}

.login-container {
  width: 90%;
  max-width: 500px;
  background: #f8f9fd;
  background: linear-gradient(
    0deg,
    rgb(255, 255, 255) 0%,
    rgb(244, 247, 251) 100%
  );
  border-radius: 40px;
  padding: 25px 35px;
  border: 5px solid rgb(255, 255, 255);
  box-shadow: rgba(133, 189, 215, 0.8784313725) 0px 30px 30px -20px;
  margin: 20px;
}

.heading {
  text-align: center;
  font-weight: 900;
  font-size: 30px;
  color: rgb(16, 137, 211);
}

.form {
  margin-top: 20px;
}

.form .input {
  width: 94%;
  height: 50px;
  background: white;
  border: none;
  padding: 0px 15px;
  border-radius: 20px;
  margin-top: 15px;
  box-shadow: #cff0ff 0px 10px 10px -5px;
  border-inline: 2px solid transparent;
}

.form .input::-moz-placeholder {
  color: rgb(170, 170, 170);
}

.form .input::placeholder {
  color: rgb(170, 170, 170);
}

.form .input:focus {
  outline: none;
  border-inline: 2px solid #740820;
}

.form .forgot-password {
  display: block;
  margin-top: 10px;
  margin-left: 10px;
}

.form .forgot-password a {
  font-size: 11px;
  color: #0099ff;
  text-decoration: none;
}

.form .login-button {
  display: block;
  width: 100%;
  height: 50px;
  font-weight: bold;
  background: linear-gradient(45deg, rgb(116, 8, 32) 0%, rgb(167, 11, 44) 100%);
  color: white;
  padding-block: 15px;
  margin: 20px auto;
  border-radius: 20px;
  box-shadow: rgba(133, 189, 215, 0.8784313725) 0px 20px 10px -15px;
  border: none;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  font-size: 13pt;
}

.form .login-button:hover {
  transform: scale(1.03);
  box-shadow: rgba(133, 189, 215, 0.8784313725) 0px 23px 10px -20px;
}

.form .login-button:active {
  transform: scale(0.95);
  box-shadow: rgba(133, 189, 215, 0.8784313725) 0px 15px 10px -10px;
}

.social-account-container {
  margin-top: 25px;
}

.social-account-container .title {
  display: block;
  text-align: center;
  font-size: 10px;
  color: rgb(170, 170, 170);
}

.social-account-container .social-accounts {
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 15px;
  margin-top: 5px;
}

.social-account-container .social-accounts .social-button {
  background: linear-gradient(45deg, rgb(0, 0, 0) 0%, rgb(112, 112, 112) 100%);
  border: 5px solid white;
  padding: 5px;
  border-radius: 50%;
  width: 40px;
  aspect-ratio: 1;
  display: grid;
  place-content: center;
  box-shadow: rgba(133, 189, 215, 0.8784313725) 0px 12px 10px -8px;
  transition: all 0.2s ease-in-out;
}

.social-account-container .social-accounts .social-button .svg {
  fill: white;
  margin: auto;
}

.social-account-container .social-accounts .social-button:hover {
  transform: scale(1.2);
}

.social-account-container .social-accounts .social-button:active {
  transform: scale(0.9);
}

.agreement {
  display: block;
  text-align: center;
  margin-top: 15px;
}

.agreement a {
  text-decoration: none;
  color: #0099ff;
  font-size: 9px;
}

.spinner {
  font-size: 28px;
  position: relative;
  display: inline-block;
  width: 1em;
  height: 1em;
  margin-top: 10px;
}

.spinner.center {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}

.spinner .spinner-blade {
  position: absolute;
  left: 0.4629em;
  bottom: 0;
  width: 0.074em;
  height: 0.2777em;
  border-radius: 0.0555em;
  background-color: transparent;
  -webkit-transform-origin: center -0.2222em;
  -ms-transform-origin: center -0.2222em;
  transform-origin: center -0.2222em;
  animation: spinner-fade9234 1s infinite linear;
}

.spinner .spinner-blade:nth-child(1) {
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
}

.spinner .spinner-blade:nth-child(2) {
  -webkit-animation-delay: 0.083s;
  animation-delay: 0.083s;
  -webkit-transform: rotate(30deg);
  -ms-transform: rotate(30deg);
  transform: rotate(30deg);
}

.spinner .spinner-blade:nth-child(3) {
  -webkit-animation-delay: 0.166s;
  animation-delay: 0.166s;
  -webkit-transform: rotate(60deg);
  -ms-transform: rotate(60deg);
  transform: rotate(60deg);
}

.spinner .spinner-blade:nth-child(4) {
  -webkit-animation-delay: 0.249s;
  animation-delay: 0.249s;
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}

.spinner .spinner-blade:nth-child(5) {
  -webkit-animation-delay: 0.332s;
  animation-delay: 0.332s;
  -webkit-transform: rotate(120deg);
  -ms-transform: rotate(120deg);
  transform: rotate(120deg);
}

.spinner .spinner-blade:nth-child(6) {
  -webkit-animation-delay: 0.415s;
  animation-delay: 0.415s;
  -webkit-transform: rotate(150deg);
  -ms-transform: rotate(150deg);
  transform: rotate(150deg);
}

.spinner .spinner-blade:nth-child(7) {
  -webkit-animation-delay: 0.498s;
  animation-delay: 0.498s;
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

.spinner .spinner-blade:nth-child(8) {
  -webkit-animation-delay: 0.581s;
  animation-delay: 0.581s;
  -webkit-transform: rotate(210deg);
  -ms-transform: rotate(210deg);
  transform: rotate(210deg);
}

.spinner .spinner-blade:nth-child(9) {
  -webkit-animation-delay: 0.664s;
  animation-delay: 0.664s;
  -webkit-transform: rotate(240deg);
  -ms-transform: rotate(240deg);
  transform: rotate(240deg);
}

.spinner .spinner-blade:nth-child(10) {
  -webkit-animation-delay: 0.747s;
  animation-delay: 0.747s;
  -webkit-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  transform: rotate(270deg);
}

.spinner .spinner-blade:nth-child(11) {
  -webkit-animation-delay: 0.83s;
  animation-delay: 0.83s;
  -webkit-transform: rotate(300deg);
  -ms-transform: rotate(300deg);
  transform: rotate(300deg);
}

.spinner .spinner-blade:nth-child(12) {
  -webkit-animation-delay: 0.913s;
  animation-delay: 0.913s;
  -webkit-transform: rotate(330deg);
  -ms-transform: rotate(330deg);
  transform: rotate(330deg);
}

@keyframes spinner-fade9234 {
  0% {
    background-color: #fff;
  }
  100% {
    background-color: transparent;
  }
}

.show-pass {
  position: absolute;
  font-size: 18pt;
  margin-top: 15px;
  right: 10px;
  color: gray;
  cursor: pointer;
}
</style>
